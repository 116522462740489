<template>
  <div class="container">
    <div class="right_cont">
      <div class="title flex_center_between_box">
        <div class="title_txt flex_center"><img src="../../assets/img/detail/title_ic1.png" />Management</div>
        <div class="title_txt-right ">
          <div class="tbl_search_box" v-show="isNameSearch">
            <div class="tbl_search">
              <el-input v-model="searchTblName" type="text" clearable placeholder="Please enter name" @change="searchPersonByName()"/>
              <img src="../../assets/img/detail/search_ic1.png" @click="searchPersonByName"/>
            </div>
          </div>
          <download-excel class="export-excel-wrapper computer_right_cont_box" :data="DetailsForm" :fields="json_fields" :header="title" name="management.xls">
          <button class="btn_export" @click="exportCompany">Export</button>
        </download-excel>
        </div>
   
      </div>
      <div class="right_cont_box">
        <div class="table_box">
  
          <el-table ref="treeTable" :data="tableData" class="computer_right_cont_box" border style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}">
            <!-- 空数据状态的插槽 -->
            <template slot="empty">
              <noDate />
            </template>
            <el-table-column label="Name of a person" sortable  prop="name" align="left" width="270" show-overflow-tooltip :resizable="false"  class-name="company_link">
              <template slot="header" >
                <span class="table_add" @click="searchPerson()">Name of a person</span>
              </template>
                       <template slot-scope="scope" >
                <span v-show="!showTranslate"  class="tbl_company">{{ scope.row.name }}</span>
                <span v-show="showTranslate" class="tbl_company">{{ scope.row.nameCn }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Position" width="328" prop="position" align="left" show-overflow-tooltip :resizable="false" />
            <el-table-column label="Age" prop="age" align="left" width="70" show-overflow-tooltip :resizable="false" />
            <el-table-column label="Gender" width="100" prop="gender" align="left" show-overflow-tooltip :resizable="false" />
            <el-table-column label="Appointment Date" sortable width="150" prop="date" align="left" show-overflow-tooltip :resizable="false" />
            <el-table-column label="Department" width="120" prop="department" align="left" show-overflow-tooltip :resizable="false" />
            <el-table-column label="Shareholder" width="120" prop="shareholder" align="right" show-overflow-tooltip :resizable="false" />
          </el-table>
          <!-- 手机端 -->
         <div class=" phone_page_bg">
                    <div class="table_box">
                          <ul class="phone_table_box">
                        <li class="phone_table_box_item" v-for="(item,index) in tableData" :key="index">
                         <div class="phone_table_box_item_right ">
                           <h1 class=" line_1" @click="setid(item)"  > {{item.name}}</h1>
                         <h2 class="line_1" > position: {{item.position}}</h2>
                           <h2 class="line_1"><span >Age:{{item.age}}</span></h2>
                              <h2 class="line_1"><span >Gender:{{item.gender}}</span></h2>
                           <h2 class="line_1"><span >Appointment Date:{{item.date}}</span></h2>
                              <h2 class="line_1"><span >Department:{{item.department}}</span></h2>
                            <h2 class="line_1"><span >Shareholder:{{item.shareholder}}</span> </h2>
                      
                         </div>
                        </li>
                    </ul>
                    </div>
           </div>
                <!-- 手机端表格 -->
           <!-- <el-table ref="treeTable" :data="tableData" class="phone_page_bg" border style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}">
       
            <template slot="empty">
              <noDate />
            </template>
            <el-table-column label="Name " sortable  prop="name" align="left"  show-overflow-tooltip :resizable="false"  class-name="company_link">
      
                <template slot-scope="scope" >
                <span v-show="!showTranslate"  class="tbl_company">{{ scope.row.name }}</span>
                <span v-show="showTranslate" class="tbl_company">{{ scope.row.nameCn }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Position"  prop="position" align="left" show-overflow-tooltip :resizable="false" />
    
            <el-table-column label="Gender"  prop="gender" align="left" show-overflow-tooltip :resizable="false" />
     

            <el-table-column label="Shareholder"  prop="shareholder" align="right" show-overflow-tooltip :resizable="false" />
          </el-table> -->
          <div class="page_i_box">
            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
            <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getManagement } from '@/api/companyApi.js'
import Page from '@/components/page'
import noDate from '@/components/tableNoDate'
import crypto from '@/utils/crypto'
import { mixins1 } from '@/mixins/index';
export default {
  mixins: [mixins1],
  components: { noDate, Page },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      isNameSearch: true,
      // searchName: '',
      searchTblName:'',
      managementTable: [],
      tableData: [],
      tableList:[],
      // 下载
      title: 'Management',
      json_fields: {
        'Name of a person': 'name',
        Position: 'position',
        Age: 'age',
        Gender: 'gender',
        'Appointment Date': 'date',
        Department: 'department',
        Shareholder: 'shareholder',
      },
      DetailsForm: [],
    }
  },
  computed: {
    //index传值 是否翻译
    showTranslate: {
      get() {
          return this.$parent.indexIsTranslate;
      },
      set() {},
    },
    curPagePower: {
      get() {
          return JSON.parse(this.$parent.powerToPage);
      },
      set() {},
    },
  },
  created() {
    this.getData()
  },
  methods: {
    setid(row){
      let routeData= this.$router.resolve({
          path: '/search/searchPerson?searchname=' + row.name + '&companyCountry=' + ''
      });
      window.open(routeData.href, '_blank');
    },
    //导出
    exportCompany() {
      if (this.curPagePower[0].power) {
        this.DetailsForm = this.managementTable
      } else {
        this.message()
          // this.$parent.getOrdersAdd(1).then(res => {
          //     if (res) this.DetailsForm = this.managementTable
          // });
      }
    },
    getData() {
      // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
      const id3a = this.$route.query.id3a
      const companyCountry = this.$route.query.companyCountry
      let params = 'id3a=' + id3a + '&companyCountry=' + companyCountry

      getManagement(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code) {
          this.managementTable = data.data
          this.tableList = data.data
          this.page.total = this.managementTable.length
          this.tableData = this.managementTable.slice(0, this.page.pageSize)
        }
      
        
      })
    },
    //分页
    onPageChange(pageNo) {
      this.page.pageNo = pageNo

      // 如果是第一页，则截取0到pageSize（每页显示多少条数据）即可 tableData
      if (pageNo == 1) {
        this.tableData = this.managementTable.slice(0, this.page.pageSize)
        return
      }

      // 当前页 - 1 * 页数 = 分割开始数 比如二页 - 1  = 1*page
      const startSize = (pageNo - 1) * this.page.pageSize
      // 分割结束数 = 分割开始数 + this.pageSize
      this.tableData = this.managementTable.slice(startSize, startSize + this.page.pageSize)

      // 如果处理后没有数据（删除了最后一页所有数据），则往前处理一页
      // if (!this.tableData.length && pageNo > 1) {
      //   this.onPageChange({ pageNo: this.page.pageNo - 1 })
      // }
    },
    currtentPageChange(pageSize) {
      this.page.pageSize = pageSize
      const pageNo = this.page.pageNo

      if (pageNo == 1) {
        this.tableData = this.managementTable.slice(0, this.page.pageSize)
        return
      }
      const startSize = (pageNo - 1) * this.page.pageSize
      this.tableData = this.managementTable.slice(startSize, startSize + this.page.pageSize)
    },
    //table 搜索
    searchPerson() {
      this.isNameSearch = !this.isNameSearch
    },

    //searchPersonByName
    searchPersonByName(){
      let list = this.tableList
      let keyWord = this.searchTblName
      var arr = []
      if (keyWord == '') {
        this.managementTable=list
      } else {
        arr = list.filter((item) => {
          return item.name.toUpperCase().indexOf(keyWord.toUpperCase())!==-1
        })
        this.managementTable=arr
      }
      this.onPageChange(1)

    }
  },
}
</script>
<style scoped>
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}
.right_cont {
  margin-top: 60px;
}
.title {
  margin-bottom: 10px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.table_box {
  position: relative;
}
.table_box /deep/ .el-table th {
  height: 40px;
}
.table_add img {
  width: 14px;
  margin-left: 4px;
}
.tbl_search_box {
  /* position: absolute;
  left: 0; */
  margin-right: 10px;
}
.title_txt-right{
  display: flex;
}

.tbl_search {
  position: relative;
  width: 290px;
  height: 40px;
  z-index: 9;
}
.tbl_search /deep/ .el-input .el-input__inner {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
  border-radius: 8px;
  border: 1px solid #1290c9;
  padding: 0 40px 0 20px;

}
.tbl_search .el-input{
  height: 100%;
  
}
.tbl_search /deep/ .el-input .el-input__suffix{
right: 40px;
}
.tbl_search img {
  position: absolute;
  width: 18px;
  right: 20px;
  top: 12px;
}
.table_box /deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #1290C9;
}
.table_box /deep/ .el-table .sort-caret.descending{
    border-top-color: #C0C4CC
}
.table_box /deep/ .el-table .sort-caret.ascending {
    border-bottom-color: #C0C4CC;
  
}
.table_box /deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #1290C9;
} 
@media (max-width: 821px) {

  .right_cont {
    margin-top: 20px !important;
}
       .phone_table_box_item{
     display: flex;
     /* justify-content: center; */
     /* align-items: center; */
     padding: 20px 0;
     border-bottom:1px solid #e0dce5;
    }
   .table_box .phone_table_box_item img {
 
     width: 25px;
     height: 15px;
     margin-top: 5px;
     flex: none;
     margin-right: 4px;
     box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right{
      font-size: 14px;
      margin-left: 20px;
         width: 85%;
         
    }
    .phone_table_box_item_right h1{
        font-size: 14px;
         font-weight: normal;
             
   
    }
     .phone_table_box_item_right h2{
        font-size: 12px;
        color: #656373;
         font-weight: normal;
         line-height: 22px;
       
       
    }
    .tbl_search{
        width: 200px !important;
    }
     .phone_table_box_item_right .line_1 {
    white-space: nowrap !important;
}
}
</style>
